.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.mt--10 {
  margin-top: -10px;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.nav-link:hover {
  text-decoration: underline;
}

.welcome-section {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  margin-top: 70px;
}

.welcome-text {
  font-size: 22px;
  font-weight: 400;
  color: #4a4a4a;
}

.welcome-text-style {
  font-size: 22px;
  font-weight: 500;
}

.current-date {
  font-size: 18px;
  font-weight: 300;
}

.image-container {
  overflow: hidden;
  position: relative;
  flex: 0.5
}

.image {
  transition: transform 0.2s ease-in-out;
  object-fit: cover; 
  width: 100%; 
  height: 100%;
}

.image:hover {
  transform: scale(1.05);
}

#dot-small::after {
  content: "\00b7";
  margin: 0 4px;
}

#max-lines-title {
  width: "100%";
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

#max-lines-description {
  width: "100%";
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
}

.back-icon {
  transition: opacity 0.3s;
  width: fit-content;
  cursor: pointer;
}

.back-icon:hover {
  opacity: 0.6;
}

.list-group-size {
  max-height: 85px;
  overflow: hidden;
}

.currency-ticker {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 14px;
  position: absolute;
  left: 0;
  right: 0;
  top: 60px;
  background-color: #e6e6e6;
  @media (max-width: 768px) {
    top: 75px;
  }
}

.currency-rate {
  color: rgb(0, 0, 0);
}
.currency-rate:last-child {
  color: blue;
}

.logo-height {
  height: 35px;
}

.bookmark {
  margin-left: -5px;
  margin-top: 5px;
}

.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.mv-100 {
  margin-top: 100px;
  margin-bottom: 100px;
}

.border-transp {
  border-color: transparent;
}

.card-style {
  flex: 1;
  height: 450px;
  overflow: hidden;
  border-color: transparent;
}

.card-body-custom {
  padding: 0;
  flex: 0.5;
}

.link-custom {
  text-decoration: none; 
  color: black;
}

.link-custom:hover {
  text-decoration: none;
  color: black;
}

.hover-link:hover {
  text-decoration: underline;
}

.counter {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.6);
  color: white;
  padding-inline: 5px;
}

.eye {
  margin-right: 6px;
  height: 12px;
}

.text-eye {
  font-size: 13px;
}



@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
